import * as React from "react"
import { debounce } from "debounce";

export const useViewport = (initialState = "1440px", { ttl = 1 } = {}) => {
   // initialState is used before the component mounts client-side
   const [width, setWidth] = React.useState(initialState)
 
   React.useEffect(() => {
     const calculateWidth = debounce(() => {
      setWidth(window.innerWidth)
     }, ttl)
 
     calculateWidth()
     window.addEventListener("resize", calculateWidth)
 
     return () => {
       // deregister event listener on component dismount
       window.removeEventListener("resize", calculateWidth)
     }
   }, [ttl])
 
   return {width}
 }

