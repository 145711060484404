import * as React from "react"
import BackgroundImage from 'gatsby-background-image'
import Arrow from '../images/arrow.png'
import MapIconWhite from '../images/icons/map-icon-white.svg'
import './hero.scss'
import Plx from 'react-plx';
import { convertToBgImage } from "gbimage-bridge"
import AniLink from "gatsby-plugin-transition-link/AniLink"






export default function Hero({ image }) {

    const slideOutDataTop = [
        {
            start: "self",
            end: "43vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 0,
                    endValue: -400,
                    property: 'translateX',
                },
            ],
        },
    ];

    const slideOutDataBottom = [
        {
            start: "self",
            end: "43vh",
            easing: "easeOutQuart",
            properties: [
                {
                    startValue: 0,
                    endValue: -600,
                    property: 'translateX',
                },
            ],
        },
    ];

    const loopArr = [
        "omhoog",
        "verder",
        "vooruit",
        "omhoog",
        "verder",
        "vooruit"
    ];

    const bgImage = convertToBgImage(image)


    return (
        <>
            <BackgroundImage
                Tag="section"
                className='outer-hero-container'
                {...bgImage}
                preserveStackingContext
            >
                <div className='inner-hero-container'>
                    <div className='banner-img-container'>
                        <div className='title-outer-container'>
                            <div className='top-half'>
                                <div className='top-left'>
                                    {/* <h2 className="top-title">Koepe</h2> */}
                                    <AniLink
                                        paintDrip
                                        to="/"
                                        duration={1.5}
                                        hex="#000000"
                                        activeClassName='active-link'
                                    >
                                        <h2 className="top-title">Koeoe</h2>
                                    </AniLink>
                                </div>
                                <div className='top-right'>
                                    <div className='border-animation-container'></div>
                                </div>
                            </div>
                            <div className='bottom-half'>
                                <div className='bottom-left'>
                                    <div className='border-animation-container'></div>
                                    <div className="fake-border-bottom-bigger-screens"></div>
                                    <div className='arrow-container'>
                                        <img src={Arrow} alt="Arrow down" />
                                    </div>
                                </div>
                                <div className='bottom-right'>
                                    <div className="hero-headers">
                                        <Plx
                                            parallaxData={slideOutDataTop}

                                        >
                                            <h1>Kijk</h1>
                                        </Plx>
                                        <Plx
                                            parallaxData={slideOutDataBottom}

                                        >
                                            <div className='looping-text'>
                                                {loopArr.map((word, i) => (
                                                    <h1 key={i} style={{ animation: `fade-animation 20s linear ${i * 3.3}s infinite` }}>{word}</h1>
                                                ))}
                                            </div>
                                        </Plx>
                                    </div>
                                    <div className='address-container'>
                                        <p><a className="google-maps-link" href="https://www.google.nl/maps/search/Koepelplein/@52.3833648,4.6434867,17z/data=!3m1!4b1" target="_blank" rel="noreferrer">Koepelplein 1 Haarlem</a><span className="map-icon"><MapIconWhite /></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
        </>
    )
}
