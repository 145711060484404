import * as React from 'react';
import { Helmet } from "react-helmet";
import Recaptcha from 'react-recaptcha';
import './contact-section.scss';






export default function ContactForm() {
    const [subName, setSubName] = React.useState('');
    const [subEmail, setSubEmail] = React.useState('');
    const [firstNameValue, setFirstNameValue] = React.useState('');
    const [emailValue, setEmailValue] = React.useState('');
    const [messageValue, setMessageValue] = React.useState('');
    const [status, setStatus] = React.useState(undefined);
    const form = React.useRef(null);
    const [isVerified, setisVerified] = React.useState(false);

    function callback(){
        console.log('The captcha has been loaded')
    }

    function verifyCallback(response){
        if(response){
            setisVerified(true)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        
        //checking rechapta
        if(isVerified){
            //logic for honeypot
            if(subName !== "" || subEmail !== "" || isNaN(firstNameValue) === false) {
                return
            }
            else{
                const formElement = e.target,
                { action, method } = formElement,
                body = new FormData(formElement);

            fetch(action, {
                method,
                body
            })
                .then((response) => response.json())
                .then((response) => {

                    console.log("message sent")
                    setStatus({ type: 'success' });
                })
                .catch((error) => {
                    console.log("error")
                    setStatus({ type: 'error', error });
                });

                setFirstNameValue("");
                setEmailValue("");
                setMessageValue("");   
            }
        } else {
            console.log("is not verified")
            setStatus({ type: 'error'});
        }

    }

    return (
        <section id="contact-outer-container" className='contact-outer-container'>
            <div className='contact-inner-container'>
                <div className='form-wrapper'>
                    <div className='instructions-container'>
                        <h2>Heb je vragen?</h2>
                        <h3>Laat het ons weten</h3>
                        <a a href="mailto:info@dekoepel.com" className="koepel-email">info@dekoepel.com</a>
                    </div>
                    {/* inserting rechapta snippet */}
                    <Helmet>
                        <script src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit" async defer>
                        </script>
                    </Helmet>
                    {/* end */}
                    <form action={`${process.env.GATSBY_GF_ENDPOINT_PROD}`} ref={form} method="post" onSubmit={handleSubmit}>
                        <div className='top-inputs'>
                            <div>
                                <label htmlFor='input_1'>Je naam</label>
                                <br />
                                <input
                                    id='input_1'
                                    value={firstNameValue}
                                    name='input_1'
                                    type='text'
                                    required
                                    autoComplete="on"
                                    placeholder='Je naam'
                                    onChange={event => {
                                        setFirstNameValue(event.target.value)
                                    }}
                                />
                                <br />
                            </div>
                            <div>
                                <label htmlFor='input_2'>Je e-mailadres</label>
                                <br />
                                <input
                                    id='input_2'
                                    value={emailValue}
                                    name='input_2'
                                    type='text'
                                    required
                                    autoComplete="on"
                                    placeholder='Je e-mailadres'
                                    onChange={event => {
                                        setEmailValue(event.target.value)
                                    }}
                                />
                            </div>
                        </div>
                        <br />
                        <label htmlFor='input_3'>Je bericht</label>
                        <br />
                        <textarea
                            id='input_3'
                            value={messageValue}
                            name='input_3'
                            type='text'
                            required
                            autoComplete="on"
                            placeholder='Mijn vraag...'
                            onChange={event => {
                                setMessageValue(event.target.value)
                            }}
                        ></textarea>

                        <label className="sub" htmlFor="name">
                        <input className="sub" autoComplete="off" type="text" id="name" name="name" placeholder="Your name here" data-lpignore="true" tabIndex="-1" value={subName}
                            onChange={event => {
                                setSubName(event.target.value)
                            }} />
                        </label>
                        <label className="sub" htmlFor="email">
                        <input className="sub" autoComplete="off" type="email" id="email" name="email" placeholder="Your e-mail here" data-lpignore="true" tabIndex="-1" value={subEmail}
                            onChange={event => {
                                setSubEmail(event.target.value)
                            }} />
                        </label>
                        <button className='submit' type='submit'>Verstuur</button>
                        {status?.type === 'success' && <p>Bedankt voor je bericht! We nemen zo snel mogelijk contact met je op.</p>}
                        {status?.type === 'error' && (
                            <p>Sorry, er ging iets mis.Heb je de captcha aangevinkt?</p>
                        )}
                        <Recaptcha 
                            sitekey={`${process.env.GATSBY_RECAPTCHA_V2_SECRET_KEY}`}
                            render="explicit"
                            verifyCallback={verifyCallback}
                            onloadCallback={callback}
                            required
                        />
                    </form>
                </div>
            </div>
        </section>

    )
}
